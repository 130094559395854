import Moralis from "moralis";
import { Fragment, useState } from "react";

import { Container } from "@mui/material";

import { ChainSection, DataProvider, HeaderBar, MultiDataGrid } from "./components";
import { ToastRoot } from "./utils";

let initialShowChainsSelection: boolean | null;
const setInitialShowChainsSelection = () => {
  Moralis.start({
    apiKey: 'SpUP2qJqY0qPajtgp5GX6K8ixqadrRGi0IY036AutM5Et85pBoV20hR3k9iB82dc',
  });

  const searchFragment = new URLSearchParams(window.location.search.slice(1));
  const fixedChain = searchFragment.get('fixedChain');
  return fixedChain?.toLowerCase() !== 'true';
};

export const App = () => {
  const [showChainsSelection] = useState(initialShowChainsSelection ?? (initialShowChainsSelection = setInitialShowChainsSelection()));

  return (
    <Fragment>
      <DataProvider>
        <Container maxWidth="lg">
          <HeaderBar />
          {showChainsSelection ? <ChainSection /> : null}
          <MultiDataGrid />
        </Container>
        <ToastRoot />
      </DataProvider>
    </Fragment>
  );
};
