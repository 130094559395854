import { BigNumber, Contract } from "ethers";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { Dispatch, Fragment, SetStateAction, SyntheticEvent, useContext, useMemo, useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, InputAdornment, MenuItem } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { ConfirmDialog, DataContext } from "../";
import { IERC20, unknown } from "../../assets";
import { awaitTransaction, Balance, chainTokenCache, CurrencyType, getSignerVaultNames, showPromiseToast, Token, VaultV2 } from "../../utils";
import { TokenRowModel } from "./token/TokenRowModel";

export type LockModalProps = {
  open: boolean;
  setTokenRows: Dispatch<SetStateAction<TokenRowModel[]>>;
  onClose(): void;
};

type BalanceWithData = Balance & {
  token: Token;
};

let confirmed = false;

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const LockModal = ({ open, setTokenRows, onClose }: LockModalProps) => {
  const [value, setValue] = useState<BalanceWithData | null>(null);
  const [vault, setVault] = useState<string>();
  const [lockPermanently, setLockPermanently] = useState(false);
  const [lockBalance, setLockBalance] = useState(0);
  const [unlockDate, setUnlockDate] = useState<Date | null>(new Date(Date.now() + 604800000));
  const [submitting, setSubmitting] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const data = useContext(DataContext);

  const balance = useMemo(() => {
    return Object.values(data.walletBalance).map((b) => {
      return {
        ...b,
        token: chainTokenCache[data.chain.id][b.id.toLowerCase()],
      };
    });
  }, [data.chain.id, data.walletBalance]);

  const handleChange = (_: SyntheticEvent, value: BalanceWithData | null) => {
    setValue(value);
  };

  const handleImgError = ({ currentTarget }: SyntheticEvent<HTMLImageElement>) => {
    currentTarget.onerror = null;
    currentTarget.src = unknown;
  };

  const formatNumber = (number: BigNumber, decimals?: BigNumber) => {
    return parseFloat(Number.parseFloat(formatUnits(number, decimals)).toFixed(6));
  };

  const onSubmit = async () => {
    setSubmitting(true);

    if (lockPermanently) {
      confirmed = false;
      setShowConfirm(true);

      do {
        await sleep(1000);
      } while (showConfirm);

      if (!confirmed) {
        setSubmitting(false);
        return;
      }
    }

    const vaultV2Contract = new VaultV2(data.wallet.signer);
    const fees = await vaultV2Contract['fees(address)'](vault!);

    const token = value!.token;

    const operationType = 'Lock';
    const currencyType = CurrencyType[token.currencyType];
    const operationName = operationType.toLowerCase() + currencyType;

    const operationArgNames: string[] = ['address'];
    const operationArgValues: any[] = [vault];

    if (token.currencyType !== CurrencyType.ETH) {
      operationArgNames.push('address');
      operationArgValues.push(value!.id);
    }

    const rawValue = parseUnits(lockBalance.toString(), token.decimals);
    operationArgNames.push('uint256');
    operationArgValues.push(rawValue);

    let suffixes = '';
    if (lockPermanently) suffixes += 'Permanently';
    else {
      operationArgNames.push('uint256');
      operationArgValues.push(BigNumber.from(Math.floor(unlockDate!.valueOf() / 1000)));
    }

    if (data.partner.length > 0) {
      suffixes += 'OnPartner';
      operationArgNames.push('address');
      operationArgValues.push(data.partner);
    }

    if (token.currencyType === CurrencyType.Token) {
      const tokenContract = new Contract(token.address, IERC20, data.wallet.signer);
      const approval = await tokenContract.allowance(data.wallet.address, vaultV2Contract.address);
      if (rawValue.gt(approval))
        await showPromiseToast(
          awaitTransaction(tokenContract.approve(vaultV2Contract.address, BigNumber.from(2).pow(256).sub(1))),
          `Approving ${currencyType}`,
          ` ${currencyType} approved`,
          `Approving ${currencyType} failed`
        );
    }

    // if (token.currencyType === CurrencyType.ERC721) {
    //     const erc721Contract = new Contract(token.address, IERC20, data.wallet.signer);
    //     const approval = await erc721Contract.allowance(data.wallet.address, vaultV2Contract.address);
    //     if (rawValue.gt(approval))
    //         await showPromiseToast(awaitTransaction(erc721Contract.approve(vaultV2Contract.address, BigNumber.from(2).pow(256).sub(1))), `Approving ${currencyType}`, ` ${currencyType} approved`, `Approving ${currencyType} failed`);
    // }

    try {
      await showPromiseToast(
        awaitTransaction(
          vaultV2Contract[`${operationName + suffixes}(${operationArgNames.join(',')})`](...operationArgValues, {
            value: (fees[`lock${currencyType}`] as BigNumber).add(token.currencyType === CurrencyType.ETH ? rawValue : 0),
          })
        ),
        `${operationType}ing ${currencyType}`,
        ` ${currencyType} ${operationType.toLowerCase()}ed`,
        `${operationType}ing ${currencyType} failed`
      );
      setTokenRows((tokenRows) => {
        return [
          ...tokenRows,
          {
            id: (tokenRows.length + 1).toString(),
            chainId: data.chain.id,
            tokenAddress: token.address,
            vault: SignerVaultIds[vault!] ?? `Vault ${data.vaultAddresses.length + 1}`,
            vaultAddress: vault!,
            symbol: token.symbol,
            name: token.name,
            value: lockBalance,
            rawValue: rawValue,
            unlocksAt: lockPermanently ? new Date(BigNumber.from(8640000000000000).toNumber()) : unlockDate!,
          },
        ];
      });
      onClose();
    } catch (e) {
      console.error(e);
    }

    setSubmitting(false);
  };

  const maxBalance = value && value.values ? formatNumber(value.values[0], value.token.decimals) : 0;
  const canSubmit = (lockPermanently || (unlockDate && !Number.isNaN(unlockDate.valueOf()))) && value && value.token && !Number.isNaN(lockBalance) && lockBalance > 0 && lockBalance <= maxBalance;

  const SignerVaultIds = getSignerVaultNames(data.chain.id, data.wallet.address);

  return (
    <Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Lock Currency</DialogTitle>
        <DialogContent>
          <TextField
            id="outlined-select-currency"
            select
            label="Select vault"
            value={vault ?? ''}
            onChange={(e) => setVault(e.target.value)}
            helperText="Please select your target vault"
            sx={{ mt: 1 }}
          >
            {data.vaultAddresses.map((option) => (
              <MenuItem key={option} value={option}>
                {SignerVaultIds[option] ?? option}
              </MenuItem>
            ))}
          </TextField>
          <Autocomplete
            onChange={handleChange}
            options={balance}
            autoHighlight
            noOptionsText="No currencies found"
            getOptionLabel={(option) => `${option.token.symbol} - ${option.token.name}`}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img loading="lazy" width="20" src={option.token.logo ?? unknown} alt="" onError={handleImgError} />
                {option.token.symbol} - {option.token.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a currency"
                variant="standard"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <img height="32" src={value && value.token.logo && value.token.logo.length > 0 ? value.token.logo : unknown} alt="" onError={handleImgError} />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'off',
                }}
              />
            )}
            sx={{ mt: 2 }}
          />
          <TextField
            sx={{ mt: 2 }}
            label="Choose an amount"
            variant="outlined"
            value={lockBalance}
            onChange={(e) => setLockBalance(Math.min(Number.parseFloat(e.target.value), maxBalance))}
            type="number"
          />
          <div>{`Balance: ${value?.values?.map((v) => formatNumber(v, value.token.decimals)).join(', ')}`}</div>
          <div style={{ marginTop: 10 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                renderInput={(props) => <TextField sx={{ mr: 1 }} {...props} />}
                label="Choose a unlock date"
                disabled={lockPermanently}
                value={unlockDate}
                onChange={(newValue) => {
                  setUnlockDate(newValue);
                }}
              />
            </LocalizationProvider>
            or
            <FormControlLabel sx={{ ml: 1 }} control={<Checkbox defaultChecked onChange={(e) => setLockPermanently(e.target.checked)} />} label="Lock permanently" />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton loading={submitting} disabled={!canSubmit} onClick={onSubmit}>
            Lock
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={showConfirm}
        title="Confirm Permanent Lock"
        descriptions={[
          'Are you sure you want to lock these tokens forever? This action cannot be undone. Permanent is permanent. No one will ever be able to access these tokens again. By processing this transaction you agree to the following:',
          'The end user and anyone claiming on the end users behalf releases and forever discharges the Vault project and its affiliates, successors, officers, employees, representatives, partners, agents and anyone claiming through them (collectively, "the team"), in their individual and/or corporate capacities from any and all claims, liabilities, obligations, promises, agreements, disputes, demands, damages, causes of action of any nature and kind, known and unknown, which the end user has or ever had or may in the future have against the team arising out of or relating to the permanent locking of cryptocurrency assets via this transaction.',
        ]}
        confirmText="Agree"
        disagreeText="Cancel"
        handleClose={() => {
          confirmed = false;
          setShowConfirm(false);
        }}
        handleConfirm={() => {
          confirmed = true;
          setShowConfirm(false);
        }}
      />
    </Fragment>
  );
};
