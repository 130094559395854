import { constants, ethers, Signer, utils, VoidSigner } from "ethers";
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useMemo, useState } from "react";

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { BalanceMap, Chain, chains } from "../../utils";

export type Wallet = {
  address: string;
  signer: ethers.Signer;
};

export type Data = {
  partner: string;
  darkMode: boolean;
  setDarkMode: Dispatch<SetStateAction<boolean>>;
  chain: Chain;
  setChain: Dispatch<SetStateAction<Chain>>;
  wallet: Wallet;
  setWallet: Dispatch<SetStateAction<Wallet>>;
  walletBalance: BalanceMap;
  setWalletBalance: Dispatch<SetStateAction<BalanceMap>>;
  vaultAddresses: string[];
  setVaultAddresses: Dispatch<SetStateAction<string[]>>;
  signerVaultAddress: string;
  setSignerVaultAddress: Dispatch<SetStateAction<string>>;
  signerVaultBalance: BalanceMap;
  setSignerVaultBalance: Dispatch<SetStateAction<BalanceMap>>;
  signerVaultLockMapId: string;
  setSignerVaultLockMapId: Dispatch<SetStateAction<string>>;
};

let initialPartner: string | null;
const setInitialPartner = () => {
  const searchFragment = new URLSearchParams(window.location.search.slice(1));
  const partner = searchFragment.get('partner');
  return partner && utils.isAddress(partner) ? partner : '';
};

let initialChain: Chain | null;
const setInitialChain = () => {
  const searchFragment = new URLSearchParams(window.location.search.slice(1));
  const chainNetwork = searchFragment.get('chain');
  if (chainNetwork) {
    const chain = chains.find((chain) => chain.network === chainNetwork.toLowerCase().replaceAll('-', ' '));
    if (chain) return chain;
  }

  const chainId = searchFragment.get('chainId');
  if (chainId && Number.parseInt(chainId)) {
    const chain = chains.find((chain) => chain.id === Number.parseInt(chainId));
    if (chain) return chain;
  }

  return chains[0];
};

export const DataContext = createContext<Data>({
  partner: setInitialPartner(),
  darkMode: true,
  setDarkMode: () => {},
  chain: setInitialChain(),
  setChain: () => {},
  wallet: { address: constants.AddressZero, signer: new VoidSigner(constants.AddressZero) as Signer },
  setWallet: () => {},
  walletBalance: {},
  setWalletBalance: () => {},
  vaultAddresses: [],
  setVaultAddresses: () => {},
  signerVaultAddress: constants.AddressZero,
  setSignerVaultAddress: () => {},
  signerVaultBalance: {},
  setSignerVaultBalance: () => {},
  signerVaultLockMapId: constants.AddressZero,
  setSignerVaultLockMapId: () => {},
});

export const DataProvider = (props: PropsWithChildren) => {
  const [partner] = useState(initialPartner ?? (initialPartner = setInitialPartner()));
  const [darkMode, setDarkMode] = useState(true);
  const [chain, setChain] = useState(initialChain ?? (initialChain = setInitialChain()));
  const [wallet, setWallet] = useState({ address: constants.AddressZero, signer: new VoidSigner(constants.AddressZero) as Signer });
  const [walletBalance, setWalletBalance] = useState<BalanceMap>({});
  const [vaultAddresses, setVaultAddresses] = useState<string[]>([]);
  const [signerVaultAddress, setSignerVaultAddress] = useState(constants.AddressZero);
  const [signerVaultBalance, setSignerVaultBalance] = useState<BalanceMap>({});
  const [signerVaultLockMapId, setSignerVaultLockMapId] = useState<string>(constants.AddressZero);

  const theme = useMemo(() => {
    const theme = chain.theme;
    theme.palette.mode = darkMode ? 'dark' : 'light';
    return theme;
  }, [darkMode, chain]);

  return (
    <DataContext.Provider
      value={{
        partner,
        darkMode,
        setDarkMode,
        chain,
        setChain,
        wallet,
        setWallet,
        walletBalance,
        setWalletBalance,
        vaultAddresses,
        setVaultAddresses,
        signerVaultAddress,
        setSignerVaultAddress,
        signerVaultBalance,
        setSignerVaultBalance,
        signerVaultLockMapId,
        setSignerVaultLockMapId,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        {props.children}
      </ThemeProvider>
    </DataContext.Provider>
  );
};
