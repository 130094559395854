import { BigNumber, constants } from "ethers";

import { EvmChain } from "@moralisweb3/common-evm-utils";
import { blue, deepPurple, green, purple, yellow } from "@mui/material/colors";
import { createTheme, Theme } from "@mui/material/styles";

import { bsc, eth, polygon } from "../assets";
import { chainBalanceCache, chainTokenCache, getChainTokenCache, Token } from "./";

export enum CurrencyType {
  ETH,
  Token,
  ERC721,
}

export type ChainNetwork = 'eth' | 'bsc' | 'polygon' | 'bsc testnet';

export type Chain = {
  id: number;
  index: number;
  network: ChainNetwork;
  evmChain: EvmChain;
  name: string;
  icon: string;
  explorer: string;
  iconExplorer: string;
  nativeToken: Token;
  theme: Theme;
};

export const chains: Chain[] = [
  {
    id: 1,
    network: 'eth' as ChainNetwork,
    evmChain: EvmChain.ETHEREUM,
    name: 'mainnet',
    icon: eth,
    explorer: 'https://etherscan.io',
    iconExplorer: 'https://assets-cdn.trustwallet.com/blockchains/ethereum/assets',
    nativeToken: {
      currencyType: CurrencyType.ETH,
      address: constants.AddressZero,
      name: 'Ether',
      symbol: 'ETH',
      logo: eth,
      decimals: BigNumber.from(18),
    },
    theme: createTheme({
      palette: {
        mode: 'dark',
        primary: {
          main: blue[700],
        },
        secondary: {
          main: purple[500],
        },
      },
    }),
  },
  {
    id: 56,
    network: 'bsc' as ChainNetwork,
    evmChain: EvmChain.BSC,
    name: 'binance',
    icon: bsc,
    explorer: 'https://bscscan.com',
    iconExplorer: 'https://assets-cdn.trustwallet.com/blockchains/smartchain/assets',
    nativeToken: {
      currencyType: CurrencyType.ETH,
      address: constants.AddressZero,
      name: 'BNB',
      symbol: 'BNB',
      logo: bsc,
      decimals: BigNumber.from(18),
    },
    theme: createTheme({
      palette: {
        mode: 'dark',
        primary: {
          main: yellow[700],
        },
        secondary: {
          main: green[500],
        },
      },
    }),
  },
  {
    id: 97,
    network: 'bsc testnet' as ChainNetwork,
    evmChain: EvmChain.BSC_TESTNET,
    name: 'binance-testnet',
    icon: bsc,
    explorer: 'https://testnet.bscscan.com/',
    iconExplorer: 'https://assets-cdn.trustwallet.com/blockchains/smartchain/assets',
    nativeToken: {
      currencyType: CurrencyType.ETH,
      address: constants.AddressZero,
      name: 'BNB',
      symbol: 'BNB',
      logo: bsc,
      decimals: BigNumber.from(18),
    },
    theme: createTheme({
      palette: {
        mode: 'dark',
        primary: {
          main: yellow[700],
        },
        secondary: {
          main: green[500],
        },
      },
    }),
  },
  {
    id: 137,
    network: 'polygon' as ChainNetwork,
    evmChain: EvmChain.POLYGON,
    name: 'matic',
    icon: polygon,
    explorer: 'https://polygonscan.com',
    iconExplorer: 'https://assets-cdn.trustwallet.com/blockchains/polygon/assets',
    nativeToken: {
      currencyType: CurrencyType.ETH,
      address: constants.AddressZero,
      name: 'Matic',
      symbol: 'MATIC',
      logo: polygon,
      decimals: BigNumber.from(18),
    },
    theme: createTheme({
      palette: {
        mode: 'dark',
        primary: {
          main: deepPurple[700],
        },
        secondary: {
          main: yellow[500],
        },
      },
    }),
  },
]
  .filter((chain) => chain.id !== 97)
  .map((chain, index) => {
    return {
      ...chain,
      index,
    };
  });

export function initializeChains() {
  chains.forEach((chain) => {
    chainBalanceCache[chain.id] = {};
    chainTokenCache[chain.id] = {
      [chain.nativeToken.address]: chain.nativeToken,
    };

    try {
      const cache = getChainTokenCache(chain.id);
      if (Object.keys(cache).length > 0)
        chainTokenCache[chain.id] = {
          ...cache,
          [chain.nativeToken.address]: chain.nativeToken,
        };
    } catch (_) {}
  });
}
