import { SyntheticEvent, useContext, useEffect, useState } from "react";

import { AppBar, Avatar, Tab, Tabs } from "@mui/material";

import { DataContext } from "../";
import { chains, getBalances, isConnected, switchToChain, VaultV2 } from "../../utils";

const getChainTabs = () => {
  const tabs: JSX.Element[] = [];

  for (var i = 0; i < chains.length; i++) {
    tabs.push(<Tab icon={<Avatar src={chains[i].icon} />} label={chains[i].network} id={`chain-tab-${i}`} key={`chain-tab-${i}`} />);
  }

  return tabs;
};

export const ChainSection = () => {
  const [value, setValue] = useState(0);
  const data = useContext(DataContext);

  const handleChange = async (_: SyntheticEvent, newValue: number) => {
    if (data.chain.index === newValue) return;

    if (!isConnected(data.wallet.address, data.wallet.signer)) {
      data.setChain(chains[newValue]);
      return;
    }

    if (await switchToChain(data.chain, chains[newValue])) {
      data.setWalletBalance(
        await getBalances({
          chain: data.chain,
          address: data.wallet.address,
          cached: true,
        })
      );
      data.setVaultAddresses(await new VaultV2(data.wallet.signer).getVaults());
    }
  };

  useEffect(() => {
    setValue(data.chain.index);
  }, [data.chain]);

  return (
    <AppBar position="static">
      <Tabs value={value} onChange={handleChange} indicatorColor="secondary" textColor="inherit" variant="fullWidth">
        {getChainTabs()}
      </Tabs>
    </AppBar>
  );
};
