import { getAddress } from "ethers/lib/utils";
import { Fragment } from "react";
import { Vote } from "utils/web3/vote";

import { Ballot, Edit, TaskAlt, Token, Visibility, Warning } from "@mui/icons-material";
import { Chip, styled } from "@mui/material";
import { DataGridProps, GridActionsCellItem, GridRenderCellParams, GridRowHeightParams, GridRowParams } from "@mui/x-data-grid";

import { VaultRowModel } from "./VaultRowModel";

const StyledChip = styled(Chip)(({ theme }) => ({
  justifyContent: 'left',
  '& .icon': {
    color: 'inherit',
  },
  '&.AlreadyVoted': {
    color: theme.palette.success.dark,
    border: `1px solid ${theme.palette.success.main}`,
  },
  '&.NotYetVoted': {
    color: theme.palette.warning.dark,
    border: `1px solid ${theme.palette.warning.main}`,
  },
}));

function OtherSignersRenderCell(params: GridRenderCellParams<string[], VaultRowModel>) {
  if (!params.value) return <Fragment />;

  return (
    <Fragment>
      {params.value.map((v, index) => {
        return <Fragment key={index}>{v}</Fragment>;
      })}
    </Fragment>
  );
}

function VoteRenderCell(params: GridRenderCellParams<Vote | undefined, VaultRowModel>) {
  if (params.rowNode.isAutoGenerated || !params.value || params.value.data === '0x') return <Fragment />;

  let status: any = null;
  let icon: any = null;
  let label: any = null;

  if (params.value.voted) {
    status = 'AlreadyVoted';
    label = 'Already Voted';
    icon = <TaskAlt className="icon" />;
  } else {
    status = 'NotYetVoted';
    label = 'Not Yet Voted';
    icon = <Warning className="icon" />;
  }

  return <StyledChip className={status} icon={icon} size="small" label={label} variant="outlined" />;
}

function GetRowHeight(params: GridRowHeightParams) {
  if (!params.model || !params.model.otherSigners || params.model.otherSigners.length < 2) return undefined;

  return (52 + 14 * (params.model.otherSigners.length - 1)) * params.densityFactor;
}

export type VaultDataGridPropsProps = {
  explorer: string;
  handleViewVaultTokens(vault: VaultRowModel): void;
  handleEditVault(vault: VaultRowModel): void;
  handleViewVaultVote(vault: VaultRowModel): void;
};

export const VaultDataGridProps = ({ explorer, handleViewVaultTokens, handleEditVault, handleViewVaultVote }: VaultDataGridPropsProps): DataGridProps<VaultRowModel> => {
  function ActionsGetActions(params: GridRowParams) {
    const hasVote: boolean = params.row.vote && params.row.vote.data !== '0x';

    return [
      <GridActionsCellItem
        icon={<Visibility color="primary" />}
        onClick={() => {
          window.open(`${explorer}/address/${getAddress(params.row.address)}`, '_blank');
        }}
        label=""
      />,
      <GridActionsCellItem icon={<Token color="primary" />} onClick={() => handleViewVaultTokens(params.row)} label="" />,
      <GridActionsCellItem icon={<Edit color="primary" />} onClick={() => handleEditVault(params.row)} label="" />,
      <GridActionsCellItem disabled={!hasVote} icon={<Ballot color={hasVote ? 'primary' : 'disabled'} />} onClick={() => handleViewVaultVote(params.row)} label="" />,
    ];
  }

  return {
    columns: [
      {
        field: 'id',
        headerName: 'Id',
        flex: 0,
        disableColumnMenu: true,
      },
      {
        field: 'address',
        headerName: 'Address',
        flex: 3,
        disableColumnMenu: true,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 2,
        disableColumnMenu: true,
      },
      {
        field: 'otherSigners',
        headerName: 'Other Signers',
        flex: 3,
        renderCell: OtherSignersRenderCell,
        disableColumnMenu: true,
      },
      {
        field: 'vote',
        headerName: 'Pending Vote',
        flex: 2,
        renderCell: VoteRenderCell,
        disableColumnMenu: true,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        flex: 1.5,
        getActions: ActionsGetActions,
        disableColumnMenu: true,
      },
    ],
    rows: [],
    rowsPerPageOptions: [25],
    density: 'compact',
    disableSelectionOnClick: true,
    getRowHeight: GetRowHeight,
  };
};
