import { get, remove, set } from "local-storage";

import { TokenMap } from "./";

const LS_MULTIPLE_TRANSACTIONS_INFO_SEEN = 'gmtis_';
const LS_CHAIN_TOKEN_CACHE = 'gctc_';
const LS_SINGER_VAULT_NAMES = 'gsvn_';

export type SignerVaultNamesMap = {
  [address: string]: string;
};

function lsKey(key: string, chain: number) {
  return key + chain.toString();
}

function lsKeyUser(key: string, chain: number, address: string) {
  return key + chain.toString() + address;
}

export function clearChainTokenCache(chain: number) {
  const key = lsKey(LS_CHAIN_TOKEN_CACHE, chain);

  remove(key);
}

export function getChainTokenCache(chain: number) {
  const key = lsKey(LS_CHAIN_TOKEN_CACHE, chain);

  const tokenMap = get<TokenMap>(key);

  return tokenMap ?? {};
}

export function setChainTokenCache(chain: number, tokenMap: TokenMap) {
  const key = lsKey(LS_CHAIN_TOKEN_CACHE, chain);

  set(key, tokenMap);
}

export function clearSignerVaultNames(chain: number, address: string) {
  const key = lsKeyUser(LS_SINGER_VAULT_NAMES, chain, address);

  remove(key);
}

export function clearSignerVaultName(chain: number, address: string, vault: string) {
  const key = lsKeyUser(LS_SINGER_VAULT_NAMES, chain, address);

  const names = getSignerVaultNames(chain, address);
  delete names[vault];

  set(key, names);
}

export function getSignerVaultNames(chain: number, address: string) {
  const key = lsKeyUser(LS_SINGER_VAULT_NAMES, chain, address);

  const names = get<SignerVaultNamesMap>(key);

  return names ?? {};
}

export function setSignerVaultName(chain: number, address: string, vault: string, name: string) {
  const key = lsKeyUser(LS_SINGER_VAULT_NAMES, chain, address);

  const names = getSignerVaultNames(chain, address);
  names[vault] = name;

  set(key, names);
}

export function clearMultipleTransactionsInfoSeen(chain: number, address: string) {
  const key = lsKeyUser(LS_MULTIPLE_TRANSACTIONS_INFO_SEEN, chain, address);

  remove(key);
}

export function getMultipleTransactionsInfoSeen(chain: number, address: string) {
  const key = lsKeyUser(LS_MULTIPLE_TRANSACTIONS_INFO_SEEN, chain, address);

  const seen = get<boolean>(key);

  return seen ?? false;
}

export function setMultipleTransactionsInfoSeen(chain: number, address: string, seen: boolean) {
  const key = lsKeyUser(LS_MULTIPLE_TRANSACTIONS_INFO_SEEN, chain, address);

  set(key, seen);
}
