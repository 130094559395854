import { BigNumber, constants, utils } from "ethers/lib";
import { formatUnits, FunctionFragment, getAddress } from "ethers/lib/utils";
import { Fragment, ReactNode } from "react";
import { Vote } from "utils/web3/vote";

import { Visibility } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import WarningIcon from "@mui/icons-material/Warning";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";

import { IVote } from "../../assets";
import { Chain, chainTokenCache, CurrencyType } from "../../utils";

export type DialogProps = {
  children?: ReactNode;
  handleClose: () => void;
};

export type GenericDialogProps = DialogProps & {
  open: boolean;
  title: string;
  contentProps: ReactNode[];
  actionsProps: ReactNode[];
};

export function GenericDialog(props: GenericDialogProps) {
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>{props.contentProps}</DialogContent>
      <DialogActions>{props.actionsProps}</DialogActions>
      {props.children}
    </Dialog>
  );
}

export type ConfirmDialogProps = DialogProps & {
  open: boolean;
  title: string;
  descriptions: string[];
  disagreeText?: string;
  confirmText?: string;
  handleConfirm: () => void;
};

export function ConfirmDialog(props: ConfirmDialogProps) {
  return (
    <GenericDialog
      open={props.open}
      handleClose={props.handleClose}
      title={props.title}
      contentProps={props.descriptions.map((description, index) => (
        <DialogContentText key={index}>{description}</DialogContentText>
      ))}
      actionsProps={[
        <Button key="Disagree" onClick={props.handleClose}>
          {props.disagreeText ?? 'Disagree'}
        </Button>,
        <Button key="Agree" onClick={props.handleConfirm} autoFocus>
          {props.confirmText ?? 'Agree'}
        </Button>,
      ]}
    >
      {props.children}
    </GenericDialog>
  );
}

export type VoteDialogProps = DialogProps & {
  chain: Chain;
  address: string;
  vote?: Vote;
  handleVote: (fragment: utils.Fragment, data: utils.Result, accept: boolean) => void;
};

export function VoteDialog(props: VoteDialogProps) {
  const vote = props.vote;
  if (!vote || vote.data === '0x') return null;

  const voteInterface = new utils.Interface(IVote);
  const fragment = voteInterface.fragments.find((fragment) => vote.data.startsWith(voteInterface.getSighash(fragment)));
  if (!fragment) {
    console.warn(`No matching fragment found for: ${vote.data}!`);
    return null;
  }

  const data = voteInterface.decodeFunctionData(fragment as FunctionFragment, vote.data);

  let title: string;
  let contentProps: ReactNode[] = [];
  let actionsProps: ReactNode[] = [];

  const ownRequest = vote.initiator === props.address;
  contentProps.push(
    <DialogContentText mb={2}>
      {`Vote initiated by: ${ownRequest ? 'You' : vote.initiator}`}
      {ownRequest ? null : (
        <IconButton color="primary" onClick={() => window.open(`${props.chain.explorer}/address/${getAddress(vote?.initiator ?? constants.AddressZero)}`, '_blank')}>
          <Visibility />
        </IconButton>
      )}
    </DialogContentText>
  );
  switch (fragment.name) {
    case 'addSignerViaVote':
      const addNominee = data[0];
      title = 'Pending vote - Add signer';
      contentProps.push(<DialogContentText>{`Should the following address get signer rights on this vault?`}</DialogContentText>);
      contentProps.push(
        <DialogContentText>
          {addNominee}
          <IconButton color="primary" onClick={() => window.open(`${props.chain.explorer}/address/${getAddress(addNominee)}`, '_blank')}>
            <Visibility />
          </IconButton>
        </DialogContentText>
      );
      break;
    case 'removeSignerViaVote':
      const removeNominee = data[0];
      title = 'Pending vote - Remove signer';
      contentProps.push(<DialogContentText>{`Should the following address lose signer rights on this vault?`}</DialogContentText>);
      contentProps.push(
        <DialogContentText>
          {removeNominee}
          <IconButton color="primary" onClick={() => window.open(`${props.chain.explorer}/address/${getAddress(removeNominee)}`, '_blank')}>
            <Visibility />
          </IconButton>
        </DialogContentText>
      );
      break;
    case 'unlockViaVote':
      const unlockAddress = data[1];
      const unlockValue = data[2];
      const unlockRecipient = data[3];

      const token = chainTokenCache[props.chain.id][unlockAddress.toLowerCase()];
      switch (token.currencyType) {
        case CurrencyType.ETH:
          title = `Pending vote - Unlock ${props.chain.nativeToken.symbol}`;
          break;
        case CurrencyType.Token:
          title = 'Pending vote - Unlock Token';
          break;
        case CurrencyType.ERC721:
          title = 'Pending vote - Unlock ERC721';
          break;
      }

      const formatNumber = (number: BigNumber, decimals?: BigNumber) => {
        return parseFloat(Number.parseFloat(formatUnits(number, decimals)).toFixed(6));
      };

      contentProps.push(<DialogContentText>{`Should the following address receive ${formatNumber(unlockValue, token.decimals)} ${token.symbol}?`}</DialogContentText>);
      contentProps.push(
        <DialogContentText>
          {unlockRecipient}
          <IconButton color="primary" onClick={() => window.open(`${props.chain.explorer}/address/${getAddress(unlockRecipient)}`, '_blank')}>
            <Visibility />
          </IconButton>
        </DialogContentText>
      );
      break;
    default:
      console.warn(`Unknown fragment to handle: ${fragment.name}`);
      return null;
  }

  contentProps.push(<DialogContentText>{`Quorom: ${vote.quorom} | Accepts: ${vote.accepts} | Rejects: ${vote.rejects}`}</DialogContentText>);
  if (!vote.voted) {
    contentProps.push(
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <WarningIcon color="warning" fontSize="large" />
        <DialogContentText textAlign="justify">You have not yet voted!</DialogContentText>
      </div>
    );
    actionsProps.push(<Button onClick={props.handleClose}>{'Vote later...'}</Button>);
    actionsProps.push(<Button onClick={() => props.handleVote(fragment, data, false)}>{'Reject the vote'}</Button>);
    actionsProps.push(<Button onClick={() => props.handleVote(fragment, data, true)}>{'Accept the vote'}</Button>);
  } else {
    contentProps.push(
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TaskAltIcon color="success" fontSize="large" />
        <DialogContentText textAlign="justify">You voted already!</DialogContentText>
      </div>
    );
    actionsProps.push(<Button onClick={props.handleClose}>{'Okay'}</Button>);
  }

  contentProps.push(
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <InfoIcon color="info" fontSize="large" />
      <DialogContentText textAlign="justify">No further vote-depending operations can be done while this vote is still pending!</DialogContentText>
    </div>
  );

  return GenericDialog({
    ...props,
    open: true,
    title,
    contentProps: contentProps.map((prop, index) => <Fragment key={index}>{prop}</Fragment>),
    actionsProps: actionsProps.map((prop, index) => <Fragment key={index}>{prop}</Fragment>),
  });
}
