import { BigNumber, constants } from "ethers";
import { getAddress } from "ethers/lib/utils";
import Moralis from "moralis";

import { unknown } from "../assets";
import { Chain, chainTokenCache, CurrencyType, setChainTokenCache, Token } from "./";

export type Lockable = {
  currencyType: CurrencyType;
  id: string;
};

export type Balance = Lockable & {
  values: BigNumber[];
};

export type LockMap = Balance & {
  initialized: boolean;
  untils: BigNumber[];
  length: BigNumber;
};

export type BalanceMap = {
  [address: string]: Balance;
};

export type AddressBalanceCache = {
  [address: string]: BalanceMap;
};

export type ChainBalanceCache = {
  [chainId: string]: AddressBalanceCache;
};

export const chainBalanceCache: ChainBalanceCache = {};

export type GetBalancesOptions = {
  chain: Chain;
  address: string;
  cached: boolean;
};

async function cacheLogo(token: Token) {
  const logo = token.logo;
  if (!logo) {
    token.logo = unknown;
    return;
  }

  await new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => {
      token.logo = unknown;
      resolve(true);
    };
    img.src = logo;
  });
}

export async function getBalances(options: GetBalancesOptions) {
  if (options.cached && chainBalanceCache[options.chain.id][options.address]) return chainBalanceCache[options.chain.id][options.address];

  const chainTokenCacheLength = Object.keys(chainTokenCache[options.chain.id]).length;
  const imageCacheOperations: Promise<any>[] = [];

  const balances: BalanceMap = {};
  const fetchOptions = { chain: options.chain.evmChain, address: options.address };

  const nativeBalanceResult = await Moralis.EvmApi.balance.getNativeBalance(fetchOptions);
  const nativeBalance = BigNumber.from(nativeBalanceResult.result.balance.toString());
  if (nativeBalance.gt(0))
    balances[constants.AddressZero.toLowerCase()] = {
      currencyType: CurrencyType.ETH,
      id: constants.AddressZero,
      values: [nativeBalance],
    };

  const tokenBalanceResults = await Moralis.EvmApi.token.getWalletTokenBalances(fetchOptions);
  tokenBalanceResults.result.forEach((tokenBalanceResult) => {
    const token = tokenBalanceResult.token;
    if (!token) return;

    const tokenAddress = token.contractAddress.checksum;
    const tokenAddressLowercase = token.contractAddress.lowercase;

    const tokenBalance = BigNumber.from(tokenBalanceResult.amount.toString());
    if (tokenBalance.gt(0))
      balances[tokenAddressLowercase] = {
        currencyType: CurrencyType.Token,
        id: tokenAddress,
        values: [tokenBalance],
      };

    if (!chainTokenCache[options.chain.id][tokenAddressLowercase]) {
      chainTokenCache[options.chain.id][tokenAddressLowercase] = {
        currencyType: CurrencyType.Token,
        address: tokenAddress,
        name: token.name,
        symbol: token.symbol,
        logo: token.logo ?? `${options.chain.iconExplorer}/${getAddress(tokenAddress)}/logo.png`,
        thumbnail: token.thumbnail === null ? undefined : token.thumbnail,
        decimals: BigNumber.from(tokenBalanceResult.decimals),
      };
      imageCacheOperations.push(cacheLogo(chainTokenCache[options.chain.id][tokenAddressLowercase]));
    }
  });

  const nftResults = await Moralis.EvmApi.nft.getWalletNFTs(fetchOptions);
  nftResults.result.forEach((nftResult) => {
    const tokenAddress = nftResult.tokenAddress.checksum;
    const tokenAddressLowercase = nftResult.tokenAddress.lowercase;

    if (!balances[tokenAddressLowercase])
      balances[tokenAddressLowercase] = {
        currencyType: CurrencyType.ERC721,
        id: tokenAddress,
        values: [BigNumber.from(nftResult.tokenId)],
      };
    else balances[tokenAddressLowercase].values.push(BigNumber.from(nftResult.tokenId));

    if (!chainTokenCache[options.chain.id][tokenAddressLowercase]) {
      chainTokenCache[options.chain.id][tokenAddressLowercase] = {
        currencyType: CurrencyType.ERC721,
        address: nftResult.tokenAddress.lowercase,
        name: nftResult.name ?? '',
        symbol: nftResult.symbol ?? '',
        logo: `${options.chain.iconExplorer}/${getAddress(tokenAddress)}/logo.png`,
      };
      imageCacheOperations.push(cacheLogo(chainTokenCache[options.chain.id][tokenAddressLowercase]));
    }
  });

  chainBalanceCache[options.chain.id][options.address] = balances;

  try {
    try {
      await Promise.all(imageCacheOperations);
    } catch (e) {
      console.error(e);
    }
    if (Object.keys(chainTokenCache[options.chain.id]).length > chainTokenCacheLength) setChainTokenCache(options.chain.id, chainTokenCache[options.chain.id]);
  } catch (e) {
    console.error(e);
  }

  return balances;
}
