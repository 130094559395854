import { utils } from "ethers";
import { getAddress } from "ethers/lib/utils";
import { Fragment, useContext, useEffect, useState } from "react";
import { Vote } from "utils/web3/vote";

import { Visibility } from "@mui/icons-material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ListItem, ListItemText, TextField } from "@mui/material";
import { Box } from "@mui/system";

import { awaitTransaction, Chain, clearSignerVaultName, setSignerVaultName, showInfoToast, showPromiseToast, SignerVaultV3, VaultV2 } from "../../utils";
import { DataContext } from "../providers";
import { ConfirmDialog } from "./Dialogs";
import { Vault } from "./vault/VaultRowModel";

export type VaultModalProps = {
  vault: Vault;
  onClose?: () => void;
  handleShowVote?: (vote: Vote) => void;
  onNameChanged?: (string: string) => void;
  onSignerAdded?: (signer: string) => void;
  onSignerRemoved?: (signer: string) => void;
};

export function VaultModal(props: VaultModalProps & { open: boolean } & { chain: Chain }) {
  const { chain, wallet } = useContext(DataContext);
  const [name, setName] = useState('');
  const [showAddSigner, setShowAddSigner] = useState(false);
  const [addSignerAddress, setAddSignerAddress] = useState('');
  const [addingSigner, setAddingSigner] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [removeSignerAddress, setRemoveSignerAddress] = useState('');
  const [removingSigner, setRemovingSigner] = useState(false);

  const handleCloseAddSigner = () => {
    setShowAddSigner(false);
  };

  const handleSubmitAddSigner = async () => {
    setAddingSigner(true);

    try {
      const vaultV2 = new VaultV2(wallet.signer);
      const vote = await showPromiseToast(vaultV2.addSigner(props.vault.address, addSignerAddress), 'Adding signer', 'Signer added', 'Adding signer failed');
      if (vote.data === '0x') props.onSignerAdded?.(addSignerAddress);
      else {
        props.vault.vote = vote;
        showInfoToast("A vote for your 'Add signer'-request has been started!");
      }
    } catch (e) {
      console.error(e);
    }

    setAddingSigner(false);
    setShowAddSigner(false);
  };

  const handleConfirmRemoveSigner = async () => {
    setRemovingSigner(true);

    try {
      const vaultV2 = new VaultV2(wallet.signer);
      const vote = await showPromiseToast(vaultV2.removeSigner(props.vault.address, removeSignerAddress), 'Removing signer', 'Signer removed', 'Removing signer failed');
      if (vote.data === '0x') props.onSignerRemoved?.(removeSignerAddress);
      else {
        props.vault.vote = vote;
        showInfoToast("A vote for your 'Remove signer'-request has been started!");
      }
    } catch (e) {
      console.error(e);
    }

    setRemovingSigner(false);
    setShowConfirm(false);
  };

  async function handleShowRemoveSigner(value: string) {
    const vote = props.vault.vote;
    if (!vote || vote.data === '0x') {
      setRemoveSignerAddress(value);
      setShowConfirm(true);
    } else props.handleShowVote?.(vote);
  }

  function handleSetName(name: string) {
    if (!name || name.length === 0) clearSignerVaultName(chain.id, wallet.address, props.vault.address);
    else setSignerVaultName(chain.id, wallet.address, props.vault.address, name);
    setName(name);
  }

  async function handleShowAddSigner() {
    const vote = props.vault.vote;
    if (!vote || vote.data === '0x') setShowAddSigner(true);
    else props.handleShowVote?.(vote);
  }

  function getErrorStr() {
    if (!utils.isAddress(addSignerAddress)) return 'The input must be a valid address';

    if (props.vault.otherSigners.includes(addSignerAddress)) return 'The address is already a signer';

    if (addSignerAddress === wallet.address) return "It's your address...";

    if (props.vault.address === wallet.address) return 'The vault itself is not an option...';

    return '';
  }

  function close() {
    props.onNameChanged?.(name);
    props.onClose?.();
  }

  const errorStr = getErrorStr();

  useEffect(() => {
    setName(props.vault.name);
  }, [props.vault.name]);

  return (
    <Fragment>
      <Dialog open={props.open} onClose={close}>
        <DialogTitle>Vault Overview</DialogTitle>
        <DialogContent>
          <DialogContentText>This is a overview of the following vault:{'\n'}</DialogContentText>
          <DialogContentText style={{ fontSize: 12 }}>
            {props.vault.address}
            <IconButton color="primary" sx={{ ml: 1, p: 0 }} onClick={() => window.open(`${props.chain.explorer}/address/${getAddress(props.vault.address)}`, '_blank')}>
              <Visibility />
            </IconButton>
          </DialogContentText>
          <DialogContentText>You have the option to give the vault a meaningful name (saved locally):</DialogContentText>
          <TextField autoFocus margin="dense" placeholder="Name the vault" value={name} fullWidth={true} onChange={(event) => handleSetName(event.target.value)} type="text" variant="standard" />
          <DialogContentText>The vault is currently managed by the following other addresses:</DialogContentText>
          {props.vault.otherSigners.length > 0 ? (
            props.vault.otherSigners.map((value) => (
              <ListItem
                key={value}
                disableGutters
                secondaryAction={
                  <Fragment>
                    <IconButton color="primary" onClick={() => window.open(`${props.chain.explorer}/address/${getAddress(value)}`, '_blank')}>
                      <Visibility />
                    </IconButton>
                    {props.vault.otherSigners.length > 1 ? (
                      <IconButton color="primary" onClick={() => handleShowRemoveSigner(value)}>
                        <PersonRemoveIcon />
                      </IconButton>
                    ) : null}
                  </Fragment>
                }
              >
                <ListItemText primary={value} />
              </ListItem>
            ))
          ) : (
            <div>None</div>
          )}
          <LoadingButton loading={showAddSigner} size="small" variant="outlined" onClick={handleShowAddSigner}>
            <PersonAddIcon fontSize="small" /> Add new signer
          </LoadingButton>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={close}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showAddSigner} onClose={handleCloseAddSigner}>
        <DialogTitle>Add Signer</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter the address of the user you want to add as a signer</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            required={true}
            placeholder="New signer's address"
            value={addSignerAddress}
            fullWidth={true}
            error={addSignerAddress.length > 0 && errorStr.length > 0}
            helperText={errorStr}
            onChange={(event) => setAddSignerAddress(event.target.value)}
            type="text"
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseAddSigner}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmitAddSigner} disabled={errorStr.length > 0}>
            Add
          </Button>
        </DialogActions>
        {addingSigner ? (
          <Fragment>
            <Box
              sx={{
                backgroundColor: '#363636a6',
                position: 'absolute',
                width: '100%',
                height: '100%',
              }}
            />
            <CircularProgress
              sx={{
                position: 'absolute',
                left: 'calc(50% - 20px)',
                bottom: 'calc(50% - 20px)',
              }}
            />
          </Fragment>
        ) : null}
      </Dialog>
      <ConfirmDialog
        open={showConfirm}
        title="Confirm removal"
        descriptions={[`Do you really want to remove the following address from the signers of ${props.vault.name}:`, removeSignerAddress]}
        confirmText="Yes"
        disagreeText="No"
        handleClose={() => setShowConfirm(false)}
        handleConfirm={handleConfirmRemoveSigner}
      >
        {removingSigner ? (
          <Fragment>
            <Box
              sx={{
                backgroundColor: '#363636a6',
                position: 'absolute',
                width: '100%',
                height: '100%',
              }}
            />
            <CircularProgress
              sx={{
                position: 'absolute',
                left: 'calc(50% - 20px)',
                bottom: 'calc(50% - 20px)',
              }}
            />
          </Fragment>
        ) : null}
      </ConfirmDialog>
    </Fragment>
  );
}
