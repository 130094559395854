import { BigNumber, constants, utils } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { Vote } from "utils/web3/vote";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import { DataGrid, DataGridProps, GridFilterModel, GridSelectionModel, GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid";

import {
  awaitTransaction,
  chainTokenCache,
  connectAndDo,
  CurrencyType,
  getBalances,
  getMultipleTransactionsInfoSeen,
  getSignerVaultNames,
  isConnectedOnCorrectChain,
  LockMap,
  setMultipleTransactionsInfoSeen,
  showInfoToast,
  showPromiseToast,
  SignerVaultV3,
  VaultV2
} from "../../utils";
import { Data, DataContext } from "../providers";
import { ConfirmDialog, VoteDialog } from "./";
import { LockModal } from "./LockModal";
import { TokenDataGridProps } from "./token/TokenDataGrid";
import { TokenRowModel } from "./token/TokenRowModel";
import { TokenToolbarButtons } from "./token/TokenToolbarButtons";
import { VaultDataGridProps } from "./vault/VaultDataGrid";
import { Vault, VaultRowModel } from "./vault/VaultRowModel";
import { VaultToolbarButtons } from "./vault/VaultToolbarButtons";
import { VaultModal, VaultModalProps } from "./VaultModal";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#0A1929',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '& .MuiFormGroup-options': {
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    '& > div': {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
  },
}));

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(','),
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
  },
  '& .MuiDataGrid-cell': {
    color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(','),
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-columnsContainer': {
      backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
      borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
    },
    '& .MuiDataGrid-cell': {
      color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
    },
    '& .MuiPaginationItem-root': {
      borderRadius: 0,
    },
    '& .MuiCheckbox-root svg': {
      width: 16,
      height: 16,
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'}`,
      borderRadius: 2,
    },
    '& .MuiCheckbox-root svg path': {
      display: 'none',
    },
    '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
      backgroundColor: '#1890ff',
      borderColor: '#1890ff',
    },
    '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
      position: 'absolute',
      display: 'table',
      border: '2px solid #fff',
      borderTop: 0,
      borderLeft: 0,
      transform: 'rotate(45deg) translate(-50%,-50%)',
      opacity: 1,
      transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
      content: '""',
      top: '50%',
      left: '39%',
      width: 5.71428571,
      height: 9.14285714,
    },
    '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
      width: 8,
      height: 8,
      backgroundColor: '#1890ff',
      transform: 'none',
      top: '39%',
      border: 0,
    },
  },
}));

type DataGridType = 'Vaults' | 'Tokens';

export type DataWithToolbarButtonProps = Data & {
  gridSelectionModel: GridSelectionModel;
  handleCreateVault(): Promise<void>;
  handleLockCurrency(): void;
  handleClaimUnlockSelected(): void;
};

export type DataWithDataGridProps = Data & {
  explorer: string;
  vaults: VaultRowModel[];
  handleViewVaultTokens(vault: Vault): void;
  handleEditVault(vault: Vault): void;
  handleViewVaultVote(vault: Vault): void;
  handleClaimOrUnlockToken(row: TokenRowModel): void;
};

const Footer = (props: { status: 'connected' | 'disconnected' }) => {
  return (
    <Box sx={{ p: 1, display: 'flex' }}>
      <FiberManualRecordIcon
        fontSize="small"
        sx={{
          mr: 1,
          color: props.status === 'connected' ? '#4caf50' : '#d9182e',
        }}
      />
      Wallet {props.status}
    </Box>
  );
};

let doNotShowAgain: boolean = false;

export function MultiDataGrid() {
  const data = useContext(DataContext);
  const [type, setType] = useState<DataGridType>('Vaults');
  const [loading, setLoading] = useState(false);
  const [vaultRows, setVaultRows] = useState<VaultRowModel[]>([]);
  const [tokenRows, setTokenRows] = useState<TokenRowModel[]>([]);
  const [gridDataFilterModel, setGridDataFilterModel] = useState<GridFilterModel>({ items: [] });
  const [gridDataSelectionModel, setGridDataSelectionModel] = useState<GridSelectionModel>([]);
  const [vaultModalOpen, setVaultModalOpen] = useState<boolean>(false);
  const [vaultModalProps, setVaultModalProps] = useState<VaultModalProps>({ vault: { address: '', name: '', otherSigners: [] } });
  const [showClaimUnlockConfirm, setShowClaimUnlockConfirm] = useState<boolean>(false);
  const [showLockModal, setShowLockModal] = useState<boolean>(false);
  const [vote, setVote] = useState<Vote>();
  const [gridData, setGridData] = useState<DataGridProps>(() => getGridData());

  const handleCloseVote = () => {
    setVote(undefined);
  };

  const handleVote = async (fragment: utils.Fragment, result: utils.Result, accept: boolean) => {
    const vaultV2 = new VaultV2(data.wallet.signer);
    const voteSucceded = await showPromiseToast(vaultV2.castVote(vaultModalProps.vault.address, accept), 'Casting vote', 'Vote casted', 'Casting vote failed');
    if (voteSucceded) {
      switch (fragment.name) {
        case 'addSignerViaVote':
          const addNominee = result[0];
          onSignerAdded(addNominee);
          break;
        case 'removeSignerViaVote':
          const removeNominee = result[0];
          onSignerRemoved(removeNominee);
          vaultModalProps.vault.otherSigners = vaultModalProps.vault.otherSigners.filter((otherSigner) => otherSigner !== removeNominee);
          break;
        case 'unlockViaVote':
      }
    }

    vaultModalProps.vault.vote = await new SignerVaultV3(vaultModalProps.vault.address, data.wallet.signer).getVote(data.wallet.address);
    setVote(undefined);
  };

  const handleCreateVault = useCallback(async () => {
    try {
      await connectAndDo(data, async (_, signer) => {
        const vaultV2 = new VaultV2(signer);
        const vault = await showPromiseToast(vaultV2.createVault(), 'Creating vault', 'Vault created', 'Creating vault failed');
        data.setVaultAddresses([...data.vaultAddresses, vault]);
      });
    } catch (e) {}
  }, [data]);

  function handleLockCurrency() {
    setShowLockModal(true);
  }

  function handleClaimUnlockSelected() {
    const seen = getMultipleTransactionsInfoSeen(data.chain.id, data.wallet.address);
    if (!seen && gridDataSelectionModel.length > 1) setShowClaimUnlockConfirm(true);
    else handleConfirmClaimUnlock(false);
  }

  async function handleConfirmClaimUnlock(byPopup: boolean) {
    if (byPopup) {
      setShowClaimUnlockConfirm(false);
      if (doNotShowAgain) setMultipleTransactionsInfoSeen(data.chain.id, data.wallet.address, true);
    }

    const rows = gridDataSelectionModel.map((id) => tokenRows.find((row) => row.id === id)).filter((row) => row) as TokenRowModel[];

    const rawOperations: {
      [vault: string]: {
        [type: string]: {
          [id: string]: {
            currencyType: CurrencyType;
            values: BigNumber[];
          };
        };
      };
    } = {};

    rows
      .filter((row) => {
        const type = row.unlocksAt.valueOf() === 0 ? 'Claim' : 'Unlock';
        const vaultRow = vaultRows.find((vaultRow) => vaultRow.address === row.vaultAddress);
        return vaultRow && (type === 'Claim' || !vaultRow.vote || vaultRow.vote.data === '0x');
      })
      .forEach((row) => {
        const token = chainTokenCache[data.chain.id][row.tokenAddress.toLowerCase()];

        const vault = vaultRows.find((vaultRow) => vaultRow.address === row.vaultAddress)!.address;
        const currencyType = token.currencyType;
        const id = row.tokenAddress;
        const value = row.rawValue;
        const type: string = row.unlocksAt.valueOf() === 0 ? 'Claim' : 'Unlock';

        if (!rawOperations[vault])
          rawOperations[vault] = {
            Claim: {},
            Unlock: {},
          };

        if (!rawOperations[vault][type][id])
          rawOperations[vault][type][id] = {
            currencyType,
            values: currencyType === CurrencyType.ERC721 ? [] : [BigNumber.from(0)],
          };

        if (currencyType === CurrencyType.ERC721) rawOperations[vault][type][id].values.push(value);
        else rawOperations[vault][type][id].values[0] = rawOperations[vault][type][id].values[0].add(value);
      });

    const claimOperations: any[] = [];
    const unlockOperations: any[] = [];

    Object.entries(rawOperations).forEach(([vault, typeDict]) => {
      Object.entries(typeDict).forEach(([type, idDict]) => {
        const currencyTypes: CurrencyType[] = [];
        const ids: string[] = [];
        const values: BigNumber[] = [];
        Object.entries(idDict).forEach(([id, entry]) => {
          entry.values.forEach((value) => {
            currencyTypes.push(entry.currencyType);
            ids.push(id);
            values.push(value);
          });
        });

        const operation = [vault, currencyTypes, ids, values, data.wallet.address];
        (type === 'Claim' ? claimOperations : unlockOperations).push(operation);
      });
    });

    const vaultV2Contract = new VaultV2(data.wallet.signer);
    if (claimOperations.length > 0) await showPromiseToast(awaitTransaction(vaultV2Contract.claimMultiple(claimOperations)), 'Claiming selected', 'Selected claimed', 'Claiming selected failed');

    if (unlockOperations.length > 0) await showPromiseToast(awaitTransaction(vaultV2Contract.unlockMultiple(unlockOperations)), 'Unlocking selected', 'Selected unlocked', 'Unlocking selected failed');

    if (claimOperations.length === 0 && unlockOperations.length === 0) showInfoToast('Nothing to claim');

    setTokenRows((tokenRows) => tokenRows.filter((r) => !rows.includes(r)));
  }

  const ToolbarButtons = useCallback(() => {
    const dataWithProps: DataWithToolbarButtonProps = {
      ...data,
      gridSelectionModel: gridDataSelectionModel,
      handleCreateVault,
      handleLockCurrency,
      handleClaimUnlockSelected,
    };

    if (type === 'Vaults') return VaultToolbarButtons(dataWithProps);

    return TokenToolbarButtons(dataWithProps);
  }, [data, gridDataSelectionModel, handleCreateVault, type]);

  const Toolbar = useCallback(() => {
    return (
      <GridToolbarContainer>
        <FormControl variant="standard" sx={{ minWidth: 90 }}>
          <Select value={type} onChange={(event) => setType(event.target.value as DataGridType)}>
            <MenuItem value="Vaults">Vaults</MenuItem>
            <MenuItem value="Tokens">Tokens</MenuItem>
          </Select>
        </FormControl>
        {ToolbarButtons()}
        <GridToolbarFilterButton />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }, [ToolbarButtons, type]);

  function handleViewVaultTokens(vault: Vault) {
    setType('Tokens');
    setTimeout(
      () =>
        setGridDataFilterModel({
          items: [
            {
              columnField: 'vaultAddress',
              operatorValue: 'equals',
              value: vault.address,
            },
          ],
        }),
      100
    );
  }

  function handleEditVault(vault: Vault) {
    setVaultModalProps({
      vault,
      onClose: () => setVaultModalOpen(false),
    });
    setVaultModalOpen(true);
  }

  function handleViewVaultVote(vault: Vault) {
    setVote(vault.vote);
  }

  async function handleClaimOrUnlockToken(row: TokenRowModel) {
    const token = chainTokenCache[data.chain.id][row.tokenAddress.toLowerCase()];

    const operationType = row.unlocksAt.valueOf() === 0 ? 'Claim' : 'Unlock';
    const currencyType = CurrencyType[token.currencyType];
    const operationName = operationType.toLowerCase() + currencyType;

    const operationArgNames: string[] = ['address'];
    const operationArgValues: any[] = [row.vaultAddress];

    if (token.currencyType !== CurrencyType.ETH) {
      operationArgNames.push('address');
      operationArgValues.push(row.tokenAddress);
    }

    if (operationType === 'Unlock' || token.currencyType === CurrencyType.ERC721) {
      operationArgNames.push('uint256');
      operationArgValues.push(row.rawValue);
    }

    operationArgNames.push('address');
    operationArgValues.push(data.wallet.address);

    const vaultV2Contract = new VaultV2(data.wallet.signer);
    await showPromiseToast(
      awaitTransaction(vaultV2Contract[`${operationName}(${operationArgNames.join(',')})`](...operationArgValues)),
      `${operationType}ing ${currencyType}`,
      ` ${currencyType} ${operationType.toLowerCase()}ed`,
      `${operationType}ing ${currencyType} failed`
    );
    const vote = await new SignerVaultV3(row.vaultAddress, data.wallet.signer).getVote(data.wallet.address);
    if (vote.data === '0x') setTokenRows((tokenRows) => tokenRows.filter((r) => r.id !== row.id));
    else {
      const vault = vaultRows.find((r) => r.address === row.vaultAddress);
      if (vault) vault.vote = vote;
      showInfoToast(`A vote for your '${operationType} ${currencyType}'-request has been started!`);
    }
  }

  function getGridData() {
    const dataWithProps: DataWithDataGridProps = {
      ...data,
      explorer: data.chain.explorer,
      vaults: vaultRows,
      handleViewVaultTokens,
      handleEditVault,
      handleViewVaultVote,
      handleClaimOrUnlockToken,
    };

    if (type === 'Vaults')
      return {
        ...VaultDataGridProps(dataWithProps),
        rows: vaultRows,
      };

    return {
      ...TokenDataGridProps(dataWithProps),
      rows: tokenRows,
    };
  }

  const getGridDataAsCallback = useCallback(getGridData, [data, type, tokenRows, vaultRows]);

  const updateRows = useCallback(async () => {
    const vaultRows: VaultRowModel[] = [];
    const tokenRows: TokenRowModel[] = [];

    const SignerVaultNames = getSignerVaultNames(data.chain.id, data.wallet.address);

    const sum = (array: BigNumber[]) => {
      return array ? array.reduce((sum, current) => sum.add(current), BigNumber.from(0)) : BigNumber.from(0);
    };

    const formatNumber = (number: BigNumber, decimals?: BigNumber) => {
      return parseFloat(Number.parseFloat(formatUnits(number, decimals)).toFixed(6));
    };

    let tokenDataId = 0;

    await Promise.all(
      data.vaultAddresses.map(async (vaultAddress, vaultIndex) => {
        const signerVault = new SignerVaultV3(vaultAddress, data.wallet.signer);
        const signers = await signerVault.getSigners();
        const vote = await signerVault.getVote(data.wallet.address);

        vaultRows.push({
          id: (vaultIndex + 1).toString(),
          address: vaultAddress,
          name: SignerVaultNames[vaultAddress] ?? `Vault ${vaultIndex + 1}`,
          otherSigners: signers.filter((sig) => sig !== data.wallet.address),
          vote,
        });

        const vaultBalances = await getBalances({
          chain: data.chain,
          address: vaultAddress,
          cached: true,
        });

        const lockMaps = await signerVault.getLockMaps(Object.keys(vaultBalances));
        lockMaps.forEach((lockMap) => {
          const balance = vaultBalances[lockMap.id];
          const token = chainTokenCache[data.chain.id][lockMap.id.toLowerCase()];

          const totalBalance = sum(balance?.values) ?? BigNumber.from(0);
          const claimableBalance = totalBalance.sub(sum(lockMap.values));

          const baseData = {
            chainId: data.chain.id,
            tokenAddress: token.address,
            vault: SignerVaultNames[vaultAddress] ?? `Vault ${vaultIndex + 1}`,
            vaultAddress: vaultAddress,
            symbol: token.symbol,
            name: token.name,
          };

          if (claimableBalance.gt(0))
            tokenRows.push({
              id: (tokenDataId++).toString(),
              ...baseData,
              value: formatNumber(claimableBalance, token.decimals),
              rawValue: claimableBalance,
              unlocksAt: new Date(0),
            });

          lockMap.values.forEach((value, index) => {
            let unlocksAt = lockMap.untils[index].mul(1000);
            if (unlocksAt.gt(8640000000000000)) unlocksAt = BigNumber.from(8640000000000000);

            tokenRows.push({
              id: (tokenDataId++).toString(),
              ...baseData,
              value: formatNumber(value, token.decimals),
              rawValue: value,
              unlocksAt: new Date(unlocksAt.toNumber()),
            });
          });
        });
      })
    );

    setVaultRows(vaultRows);
    setTokenRows(tokenRows);
  }, [data.chain, data.vaultAddresses, data.wallet]);

  useEffect(() => {
    const gridDataProps = getGridDataAsCallback();
    gridDataProps.rows = type === 'Vaults' ? vaultRows : tokenRows;
    setGridData(gridDataProps);
    setLoading(false);
  }, [type, gridDataSelectionModel, tokenRows, vaultRows, getGridDataAsCallback]);

  useEffect(() => {
    setLoading(true);
    if (isConnectedOnCorrectChain(data.wallet.address, data.wallet.signer, data.chain) && data.vaultAddresses.length > 0) updateRows();
    else {
      setVaultRows([]);
      setTokenRows([]);
    }
  }, [data.chain, data.wallet, data.vaultAddresses, updateRows]);

  function onSignerAdded(signer: string) {
    const vault = vaultRows.find((row) => vaultModalProps.vault.address === row.address);
    if (vault) vault.otherSigners.push(signer);
  }

  function onSignerRemoved(signer: string) {
    const vault = vaultRows.find((row) => vaultModalProps.vault.address === row.address);
    if (vault) vault.otherSigners = vault.otherSigners.filter((otherSigner) => otherSigner !== signer);
  }

  return (
    <Fragment>
      <StyledBox sx={{ height: `${gridData.rows.length < 10 ? '100%' : '600px'}` }}>
        <StyledDataGrid
          {...gridData}
          autoHeight={gridData.rows.length < 10}
          loading={loading}
          componentsProps={{
            ...gridData.componentsProps,
            footer: { status: data.wallet.address !== constants.AddressZero ? 'connected' : 'disconnected' },
          }}
          components={{
            ...gridData.components,
            Footer,
            Toolbar,
          }}
          filterModel={gridDataFilterModel}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                chainId: false,
                tokenAddress: false,
                vaultAddress: false,
                rawValue: false,
              },
            },
          }}
          onFilterModelChange={(model) => setGridDataFilterModel(model)}
          onSelectionModelChange={(model) => setGridDataSelectionModel(model)}
        />
      </StyledBox>
      <VaultModal
        {...vaultModalProps}
        open={vaultModalOpen}
        chain={data.chain}
        handleShowVote={(vote: Vote) => setVote(vote)}
        onSignerAdded={onSignerAdded}
        onSignerRemoved={onSignerRemoved}
        onNameChanged={(name) => {
          const vault = vaultRows.find((row) => row.address === vaultModalProps.vault.address);
          if (vault) {
            const newName = name.length > 0 ? name : `Vault ${vault.id}`;
            vault.name = newName;
            tokenRows
              .filter((row) => row.vaultAddress === vaultModalProps.vault.address)
              .forEach((row) => {
                row.vault = newName;
              });
          }
        }}
      />
      <ConfirmDialog
        open={showClaimUnlockConfirm}
        title="Confirm Claim/Unlock"
        descriptions={[`Claiming / Unlocking multiple positions at once will reduce the amount of transactions, nonetheless, multiple transactions may be required.`]}
        confirmText="Agree"
        disagreeText="Cancel"
        handleClose={() => setShowClaimUnlockConfirm(false)}
        handleConfirm={() => handleConfirmClaimUnlock(true)}
      >
        <FormGroup sx={{ position: 'absolute', bottom: 7, left: 25 }}>
          <FormControlLabel control={<Checkbox onChange={(_, checked) => (doNotShowAgain = checked)} />} label="Do not show again for this chain & wallet" />
        </FormGroup>
      </ConfirmDialog>
      <VoteDialog handleClose={handleCloseVote} chain={data.chain} address={data.wallet.address} vote={vote} handleVote={handleVote} />
      <LockModal open={showLockModal} setTokenRows={setTokenRows} onClose={() => setShowLockModal(false)}></LockModal>
    </Fragment>
  );
}
