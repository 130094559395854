import { Fragment } from "react";

import { KeyboardArrowRight } from "@mui/icons-material";
import { Button } from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";

export type TokenToolbarButtonsProps = {
  gridSelectionModel: GridSelectionModel;
  handleLockCurrency(): void;
  handleClaimUnlockSelected(): void;
};

export function TokenToolbarButtons({ gridSelectionModel, handleLockCurrency, handleClaimUnlockSelected }: TokenToolbarButtonsProps) {
  return (
    <Fragment>
      <Button size="small" variant="outlined" onClick={handleLockCurrency}>
        <KeyboardArrowRight fontSize="small" /> Lock Currency
      </Button>
      <Button size="small" variant="outlined" disabled={gridSelectionModel.length === 0} onClick={handleClaimUnlockSelected}>
        <KeyboardArrowRight fontSize="small" /> Claim/Unlock Selected
      </Button>
    </Fragment>
  );
}
