import { unknown } from "assets";
import { Fragment, SyntheticEvent } from "react";
import { chainTokenCache } from "utils";

import { CurrencyExchange, GetApp, Lock, LockClock, LockOpen } from "@mui/icons-material";
import { Chip, styled } from "@mui/material";
import { DataGridProps, GridActionsCellItem, GridRenderCellParams, GridRowParams, GridValueFormatterParams } from "@mui/x-data-grid";

import { Vault } from "../vault/VaultRowModel";
import { TokenRowModel } from "./TokenRowModel";

const StyledChip = styled(Chip)(({ theme }) => ({
  justifyContent: 'left',
  '& .icon': {
    color: 'inherit',
  },
  '&.Claimable': {
    color: theme.palette.success.dark,
    border: `1px solid ${theme.palette.success.main}`,
  },
  '&.Unlockable': {
    color: theme.palette.info.dark,
    border: `1px solid ${theme.palette.info.main}`,
  },
  '&.Locked': {
    color: theme.palette.warning.dark,
    border: `1px solid ${theme.palette.warning.main}`,
  },
  '&.PermanentlyLocked': {
    color: theme.palette.error.dark,
    border: `1px solid ${theme.palette.error.main}`,
  },
}));

function UnlocksAtRenderCell(params: GridRenderCellParams<Date, TokenRowModel>) {
  if (params.rowNode.isAutoGenerated || !params.value) return <Fragment />;

  let status: any = null;
  let icon: any = null;
  let label: any = null;

  const unlocksAtValue = params.value.valueOf();
  if (unlocksAtValue === 0) {
    status = label = 'Claimable';
    icon = <CurrencyExchange className="icon" />;
  } else if (unlocksAtValue <= Date.now()) {
    status = label = 'Unlockable';
    icon = <LockOpen className="icon" />;
  } else if (unlocksAtValue === 8640000000000000) {
    status = 'PermanentlyLocked';
    label = 'Permanently Locked';
    icon = <Lock className="icon" />;
  } else {
    status = 'Locked';
    label = `Locked until ${params.row.unlocksAt.toLocaleString()}`;
    icon = <LockClock className="icon" />;
  }

  return <StyledChip className={status} icon={icon} size="small" label={label} variant="outlined" />;
}

function onImgError({ currentTarget }: SyntheticEvent<HTMLImageElement>) {
  currentTarget.onerror = null;
  currentTarget.src = unknown;
}

function SymbolRenderCell(params: GridRenderCellParams<string, TokenRowModel>) {
  if (!params.value) return <Fragment />;

  return (
    <div style={{ display: 'flex' }}>
      <img loading="lazy" width="20" src={chainTokenCache[params.row.chainId][params.row.tokenAddress.toLowerCase()]?.logo ?? unknown} alt="" onError={onImgError} style={{ marginRight: 4 }} />
      {params.value}
    </div>
  );
}

function ValueValueFormatter(params: GridValueFormatterParams<number>) {
  return params.value;
}

export type TokenDataGridPropsProps = {
  vaults: Vault[];
  handleClaimOrUnlockToken(token: TokenRowModel): void;
};

export function TokenDataGridProps({ vaults, handleClaimOrUnlockToken }: TokenDataGridPropsProps): DataGridProps<TokenRowModel> {
  function canClaimOrUnlock(token: TokenRowModel) {
    if (!token) return false;

    const unlocksAt = token.unlocksAt.valueOf();
    if (unlocksAt === 0) return true;
    if (unlocksAt > Date.now()) return false;

    const vault = vaults.find((vault) => vault.address === token.vaultAddress);
    return !vault || !vault.vote || vault.vote.data === '0x';
  }

  function ActionsGetActions(params: GridRowParams) {
    const claimOrUnlock = canClaimOrUnlock(params.row);
    return [<GridActionsCellItem disabled={!claimOrUnlock} icon={<GetApp color={claimOrUnlock ? 'primary' : 'disabled'} />} onClick={() => handleClaimOrUnlockToken(params.row)} label="" />];
  }

  return {
    columns: [
      {
        field: 'id',
        headerName: 'Id',
        flex: 0,
        disableColumnMenu: true,
      },
      {
        field: 'chainId',
        headerName: 'Token Chain',
        flex: 0,
        disableColumnMenu: true,
      },
      {
        field: 'tokenAddress',
        headerName: 'Token Address',
        flex: 0,
        disableColumnMenu: true,
      },
      {
        field: 'vault',
        headerName: 'Vault',
        flex: 2,
        disableColumnMenu: true,
      },
      {
        field: 'vaultAddress',
        headerName: 'Vault Address',
        flex: 0,
        disableColumnMenu: true,
      },
      {
        field: 'symbol',
        headerName: 'Symbol',
        flex: 1.5,
        renderCell: SymbolRenderCell,
        disableColumnMenu: true,
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 2,
        disableColumnMenu: true,
      },
      {
        field: 'value',
        headerName: 'Amount / TokenId',
        type: 'number',
        flex: 2,
        valueFormatter: ValueValueFormatter,
        disableColumnMenu: true,
      },
      {
        field: 'rawValue',
        headerName: 'Value',
        flex: 0,
        disableColumnMenu: true,
      },
      {
        field: 'unlocksAt',
        headerName: 'Status',
        type: 'dateTime',
        flex: 3,
        renderCell: UnlocksAtRenderCell,
        disableColumnMenu: true,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        flex: 1,
        getActions: ActionsGetActions,
        disableColumnMenu: true,
      },
    ],
    rows: [],
    rowsPerPageOptions: [25],
    density: 'compact',
    checkboxSelection: true,
    disableSelectionOnClick: true,
    isRowSelectable: (params: GridRowParams<TokenRowModel>) => canClaimOrUnlock(params.row),
  };
}
