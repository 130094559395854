import { constants } from "ethers";
import { getAddress } from "ethers/lib/utils";
import { MouseEvent, useContext, useState } from "react";

import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { DataContext } from "../";
import { logo } from "../../assets";
import { connect, disconnect, getBalances, showPromiseToast, showSuccessToast, VaultV2 } from "../../utils";

export const HeaderBar = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const data = useContext(DataContext);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // const handleDarkMode = () => {
  //   data.setDarkMode(!data.darkMode);
  // };

  const handleViewInExplorer = () => {
    handleCloseUserMenu();
    window.open(`${data.chain.explorer}/address/${getAddress(data.wallet.address)}`, '_blank');
  };

  const handleDisconnect = async () => {
    handleCloseUserMenu();
    await disconnect(data);
    data.setWalletBalance({});
    data.setVaultAddresses([]);
    showSuccessToast('Wallet disconnected');
  };

  const handleConnect = async () => {
    handleCloseUserMenu();
    const { signer, address } = await showPromiseToast(connect(data), 'Connecting wallet', 'Wallet connected', 'Connecting wallet failed');
    if (!address || !signer) return;

    data.setWalletBalance(
      await getBalances({
        chain: data.chain,
        address,
        cached: true,
      })
    );
    data.setVaultAddresses(await new VaultV2(signer).getVaults());
  };

  const settings =
    data.wallet.address !== constants.AddressZero
      ? [
          // { text: 'Toggle Dark Mode', function: handleDarkMode },
          { text: 'View In Explorer', function: handleViewInExplorer },
          { text: 'Disconnect', function: handleDisconnect },
        ]
      : [
          // { text: 'Toggle Dark Mode', function: handleDarkMode },
          { text: 'Connect', function: handleConnect },
        ];

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Avatar
            src={logo}
            sx={{
              display: { xs: 'flex', md: 'flex' },
              mr: 1,
            }}
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'flex' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            VAULT
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton color="primary" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <AccountBalanceWalletIcon fontSize="large" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.text} onClick={setting.function}>
                  <Typography textAlign="center">{setting.text}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
