import { constants, ethers, Signer, VoidSigner } from "ethers";
import Web3 from "web3";
import Web3Modal, { IProviderOptions } from "web3modal";

import { TransactionResponse } from "@ethersproject/abstract-provider";
import WalletConnectProvider from "@walletconnect/web3-provider";

import { Data } from "../components";
import { getBalances } from "./balances";
import { Chain, chains } from "./chains";
import { showPromiseToast } from "./toasts";
import { VaultV2 } from "./web3/vaultV2";

const web3 = new Web3();

const providerOptions: IProviderOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        1: 'https://eth-mainnet.public.blastapi.io',
        56: 'https://bsc-dataseed.binance.org/',
        137: 'https://polygon-rpc.com/',
      },
    },
  },
  binancechainwallet: {
    package: true,
  },
};

let provider: ethers.providers.Web3Provider | undefined;
let web3Modal: Web3Modal | undefined;

export async function disconnect(data: Data) {
  provider = undefined;
  web3Modal?.clearCachedProvider();
  web3Modal = undefined;
  data.setWallet({ address: ethers.constants.AddressZero, signer: new ethers.VoidSigner(ethers.constants.AddressZero) });
}

export async function connect(data: Data) {
  web3Modal = new Web3Modal({
    network: data.chain.name,
    cacheProvider: false,
    disableInjectedProvider: false,
    providerOptions,
  });

  const instance = await web3Modal.connect();
  instance.on('accountsChanged', (accounts: string[]) => {
    if (accounts.length > 0 && web3.utils.isAddress(accounts[0])) {
      data.setWallet({
        ...data.wallet,
        address: accounts[0],
      });
    }
  });
  instance.on('chainChanged', async (chainId: string) => {
    if (provider) {
      const newChain = Number.parseInt(chainId, 16);
      const newChainIndex = chains.findIndex((chain) => chain.id === newChain);
      if (newChainIndex !== -1) data.setChain(chains[newChainIndex]);
      else {
        let targetChainId = data.chain.id;
        let providerNetwork = await provider.getNetwork();
        while (provider.provider.request && providerNetwork.chainId !== targetChainId) {
          try {
            await provider.provider.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: `0x${targetChainId.toString(16)}` }],
            });
          } catch (_) {}
          providerNetwork = await provider.getNetwork();
        }
      }
    }
  });
  instance.on('disconnect', () => {
    provider = undefined;
    data.setWallet({ address: ethers.constants.AddressZero, signer: new ethers.VoidSigner(ethers.constants.AddressZero) });
  });
  provider = new ethers.providers.Web3Provider(instance, 'any');

  let chainId = data.chain.id;
  let providerNetwork = await provider.getNetwork();
  while (provider.provider.request && providerNetwork.chainId !== chainId) {
    try {
      await provider.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      });
    } catch (_) {}
    providerNetwork = await provider.getNetwork();
  }

  const signer = provider.getSigner();
  const address = await signer.getAddress();
  data.setWallet({ address, signer });
  return { signer, address };
}

export function isConnected(address: string, signer: Signer) {
  return address !== constants.AddressZero && !(signer instanceof VoidSigner);
}

export function isConnectedOnCorrectChain(address: string, signer: Signer, chain: Chain) {
  return isConnected(address, signer) && provider?.network.chainId === chain.id;
}

export async function switchToChain(chain: Chain, newChain: Chain | undefined) {
  if (!newChain) newChain = chain;

  if (provider) {
    let chainId = newChain.id;
    let providerNetwork = await provider.getNetwork();
    if (provider.provider.request && providerNetwork.chainId !== chainId) {
      try {
        await provider.provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${chainId.toString(16)}` }],
        });
      } catch (_) {}
      providerNetwork = await provider.getNetwork();
      return providerNetwork.chainId === chainId;
    }
  }

  return false;
}

export async function connectAndDo<T>(data: Data, promise?: (data: Data, signer: Signer, address: string) => Promise<T>) {
  if (data.wallet.address === constants.AddressZero) {
    const { signer, address } = await showPromiseToast(connect(data), 'Connecting wallet', 'Wallet connected', 'Connecting wallet failed');
    data.setWalletBalance(
      await getBalances({
        chain: data.chain,
        address,
        cached: true,
      })
    );
    data.setVaultAddresses(await new VaultV2(signer).getVaults());
    if (promise) return await promise(data, signer, address);
  }

  if (promise) return await promise(data, data.wallet.signer, data.wallet.address);
}

export async function awaitTransaction(transactionRequest: Promise<TransactionResponse>) {
  const tx = await transactionRequest;
  return await tx.wait();
}
