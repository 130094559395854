import { IVaultV2 } from "assets";
import { Contract, providers, Signer } from "ethers";

import { TransactionResponse } from "@ethersproject/abstract-provider";

import { SignerVaultV3 } from "./signerVaultV3";
import { Vote } from "./vote";

const vaultV2Address = '0x48D6204E3802CC4161F4f85b27d372719eB7d34a';

export class VaultV2 extends Contract {
  private readonly signerOrProvider: Signer | providers.Provider;

  constructor(signerOrProvider: Signer | providers.Provider) {
    super(vaultV2Address, IVaultV2, signerOrProvider);
    this.signerOrProvider = signerOrProvider;
  }

  async getVersion(): Promise<number> {
    return (await this.version()).toNumber();
  }

  async getVaults(): Promise<string[]> {
    return await this.vaults();
  }

  async createVault(): Promise<string> {
    const tx: TransactionResponse = await super.createVault();
    const receipt = await tx.wait();
    const event = receipt.logs.map((log) => this.interface.parseLog(log)).filter((event) => event.name === 'VaultCreated')[0];
    return event.args.vault as string;
  }

  async castVote(vault: string, accept: boolean): Promise<boolean> {
    const tx: TransactionResponse = await super.castVote(vault, accept);
    const receipt = await tx.wait();
    const event = receipt.logs.map((log) => this.interface.parseLog(log)).filter((event) => event.name === 'VoteOperationExecuted')[0];
    return event.args.succes as boolean;
  }

  async addSigner(vault: string, nominee: string): Promise<Vote> {
    const tx: TransactionResponse = await super.addSigner(vault, nominee);
    await tx.wait();

    return await new SignerVaultV3(vault, this.signerOrProvider).getVote(await this.signer.getAddress());
  }

  async removeSigner(vault: string, nominee: string): Promise<Vote> {
    const tx: TransactionResponse = await super.removeSigner(vault, nominee);
    await tx.wait();

    return await new SignerVaultV3(vault, this.signerOrProvider).getVote(await this.signer.getAddress());
  }
}
