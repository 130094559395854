import { ISignerVaultV3 } from "assets";
import { BigNumber, Contract, providers, Signer } from "ethers";
import { LockMap } from "utils/balances";
import { CurrencyType } from "utils/chains";
import { chainTokenCache, Token } from "utils/tokens";

import { Vote } from "./vote";

type RawLockMap = {
  id: string;
  4: BigNumber[];
  untils: BigNumber[];
  _length: BigNumber;
  initialized: boolean;
};

export class SignerVaultV3 extends Contract {
  constructor(addressOrName: string, signerOrProvider: Signer | providers.Provider) {
    super(addressOrName, ISignerVaultV3, signerOrProvider);
  }

  private parseLockMap(currencyType: CurrencyType, lockMap: RawLockMap): LockMap {
    return {
      currencyType: currencyType,
      id: lockMap.id.toLowerCase(),
      values: lockMap[4],
      untils: lockMap.untils,
      length: lockMap._length,
      initialized: lockMap.initialized,
    };
  }

  async supportsMultipleOperations(): Promise<boolean> {
    return (await this.getVersion()) >= 3;
  }

  async getVersion(): Promise<number> {
    return (await this.version()).toNumber();
  }

  async getVoteInitiator(): Promise<string | undefined> {
    try {
      const initiator: string = await this.voteInitiator();
      return initiator;
    } catch (e) {}

    return undefined;
  }

  async getVote(voter: string): Promise<Vote> {
    const vote: Vote = {
      ...(await this.vote(voter)),
      initiator: await this.getVoteInitiator(),
    };

    return vote;
  }

  async getSigners(): Promise<string[]> {
    return await this.signers();
  }

  async getLockMap(id: string): Promise<LockMap> {
    const { chainId } = await this.provider.getNetwork();

    const currencyType = chainTokenCache[chainId][id.toLowerCase()].currencyType;
    switch (currencyType) {
      case CurrencyType.ETH:
        return this.parseLockMap(CurrencyType.ETH, await this.lockMapETH());
      case CurrencyType.Token:
        return this.parseLockMap(CurrencyType.Token, await this.lockMapToken(id));
      case CurrencyType.ERC721:
        return this.parseLockMap(CurrencyType.ERC721, await this.lockMapERC721(id));
      default:
        throw new Error('Unexpected CurrencyType', currencyType);
    }
  }

  async getLockMaps(ids: string[]): Promise<LockMap[]> {
    const { chainId } = await this.provider.getNetwork();
    if (await this.supportsMultipleOperations()) {
      return (await this.lockMapMultiple(ids)).map((rawLockMap: RawLockMap) => this.parseLockMap(chainTokenCache[chainId][rawLockMap.id.toLowerCase()].currencyType, rawLockMap));
    } else return await Promise.all(ids.map(async (id) => this.getLockMap(id)));
  }
}
