import "react-toastify/dist/ReactToastify.css";

import { toast, ToastContainer } from "react-toastify";

const toastPositon = 'bottom-right';

export function showInfoToast(content: string) {
  toast.info(content, {
    position: toastPositon,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function showSuccessToast(content: string) {
  toast.success(content, {
    position: toastPositon,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function showErrorToast(content: string) {
  toast.error(content, {
    position: toastPositon,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export async function showPromiseToast<T>(promise: Promise<T>, pendingText: string, successText: string, errorText: string) {
  return await toast.promise(promise, {
    pending: pendingText,
    success: successText,
    error: errorText,
  });
}

export function ToastRoot() {
  return <ToastContainer position={toastPositon} autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />;
}
