import { useState } from "react";

import { KeyboardArrowRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

export type VaultToolbarButtonsProps = {
  handleCreateVault(): Promise<void>;
};

export function VaultToolbarButtons({ handleCreateVault }: VaultToolbarButtonsProps) {
  const [loading, setLoading] = useState(false);

  async function onCreateVault() {
    setLoading(true);
    await handleCreateVault();
    setLoading(false);
  }

  return (
    <LoadingButton loading={loading} size="small" variant="outlined" onClick={onCreateVault}>
      <KeyboardArrowRight fontSize="small" /> Create New Vault
    </LoadingButton>
  );
}
