import { BigNumber } from "ethers";

import { CurrencyType } from "./";

export type Token = {
  currencyType: CurrencyType;
  address: string;
  name: string;
  symbol: string;
  logo?: string;
  thumbnail?: string;
  decimals?: BigNumber;
};

export type TokenMap = {
  [address: string]: Token;
};

export type ChainTokenCache = {
  [chainId: string]: TokenMap;
};

export const chainTokenCache: ChainTokenCache = {};
